<template>
  <div>
    <Modal v-model="tipStatus" label-position="left" width="900" :footer-hide="true" :mask-closable="false" class-name="vertical-center-modal" title="条码规则" @on-visible-change="closeRule">
      <div class="fromDiv">
        <div class="formLine">
          <FromLabel label="主条码(DI)"></FromLabel>
          <div class="allWidth">
            <Select class="iviewIptWidth230" v-model="choodeCode" @on-change="changeCode">
              <Option v-for="(item, index) in codeList" :key="index" :value="item.name">{{ item.name }}</Option>
            </Select>
            <span style="width: 42px;margin-left: 16px"></span>
            <i-input placeholder="请输入" class="iviewIptWidth230" type="text" v-model="ipt1" :maxlength="codeLength" @on-blur="iptChange('ipt1')"></i-input>
          </div>
        </div>
        <div class="formLine borderTopNone">
          <FromLabel label="次条码(PI)"></FromLabel>
          <div class="allWidth">
            <!--            <i-input placeholder="请输入" class="iviewIptWidth557"></i-input>-->
          </div>
        </div>

        <div v-if="barcodeRule.length == 1">
          <div class="formLine borderTopNone">
            <FromLabel label="识别码1"></FromLabel>
            <div class="allWidth">
              <Select class="iviewIptWidth230" v-model="barcodeRule[0].identifier" @on-change="ruleChange($event, 0)">
                <Option v-for="(item, index) in ruleList" :key="index" :value="item.identifier">{{ item.identifier }}</Option>
              </Select>
              <span style="width: 42px;margin-left: 16px">长度：</span>
              <i-input :placeholder="barcodeRule[0].placeholder" type="text" class="iviewIptWidth230" :maxlength="barcodeRule[0].maxlength" :disabled="barcodeRule[0].fixed_length != '0'" v-model="barcodeRule[0].length" @on-blur="iptChangeList(0)"></i-input>
              <img src="../assets/images/addLogistics.png" style="margin: 0 14px" @click="addRow(0)" />
            </div>
          </div>
        </div>
        <div v-else>
          <div v-for="(item, index) in barcodeRule" :key="index">
            <div class="formLine borderTopNone">
              <FromLabel :label="`识别码${index + 1}`"></FromLabel>
              <div class="allWidth">
                <Select class="iviewIptWidth230" v-model="item.identifier" @on-change="ruleChange($event, index)">
                  <Option v-for="(subitem, subindex) in ruleList" :key="subindex" :value="subitem.identifier" :disabled="allowSelect(subitem.identifier)">{{ subitem.identifier }}</Option>
                </Select>
                <span style="width: 42px;margin-left: 16px">长度：</span>
                <i-input :placeholder="item.placeholder" type="text" class="iviewIptWidth230" :maxlength="item.maxlength" v-model="item.length" @on-blur="iptChangeList(index)" :disabled="item.fixed_length != '0'"></i-input>
                <img src="../assets/images/addLogistics.png" style="margin: 0 14px" @click="addRow(index)" />
                <img src="../assets/images/deleteLogistics.png" @click="deleteRow(index)" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <span class="pageBtn finger marginLeft20 btnSure" @click="sureDelete">确定</span>
      </div>
    </Modal>
  </div>
</template>
<script>
import FromLabel from '@/components/formLabel'
export default {
  name: 'tipsComponent',
  components: {
    FromLabel
  },
  computed: {
    allowSelect(value) {
      let that = this
      return function(value) {
        for (let i = 0; i < that.barcodeRule.length; i++) {
          if (that.barcodeRule[i].identifier == value) {
            return true
          }
        }
      }
    }
  },
  data() {
    return {
      tipStatus: true,
      ipt1: '',
      barcodeRule: [],
      codeList: [
        {
          name: '00',
          value: '18'
        },
        {
          name: '01',
          value: '14'
        },
        {
          name: '02',
          value: '14'
        }
      ],
      choodeCode: '',
      codeLength: 1
    }
  },
  props: {
    showModal: String,
    master_barcode: String, // 住条码
    master_barcode_identifier: String,
    secondary_barcode_rule: Array, // 识别码
    ruleList: Array
  },
  mounted() {
    this.choodeCode = this.master_barcode_identifier
    this.ipt1 = this.master_barcode
    if (this.secondary_barcode_rule.length == 0) {
      this.barcodeRule = [
        {
          identifier: '',
          length: ''
        }
      ]
    } else {
      this.barcodeRule = this.secondary_barcode_rule
    }
    for (let i = 0; i < this.codeList.length; i++) {
      if (this.choodeCode == this.codeList[i].name) {
        this.codeLength = this.codeList[i].value
      }
    }
    // 限制选手某个选项后长度的最大值
    for (let i = 0; i < this.ruleList.length; i++) {
      if (this.ruleList[i].length.length == 2) {
        this.ruleList[i].maxlength = this.ruleList[i].length * 1
      } else if (this.ruleList[i].length.length == 3) {
        this.ruleList[i].maxlength = this.ruleList[i].length.substring(0, 1) * 1
      } else if (this.ruleList[i].length.length == 4) {
        this.ruleList[i].maxlength = this.ruleList[i].length.substring(0, 2) * 1
      }
    }
  },
  methods: {
    iptChangeList(num) {
      this.$set(this.barcodeRule[num], 'length', this.$utils.onlyNumFormat(this.barcodeRule[num].length))
    },
    iptChange(name) {
      this[name] = this.$utils.onlyNumFormat(this[name])
      console.log(this.ipt1)
    },
    changeCode(e) {
      this.ipt1 = ''
      for (let i = 0; i < this.codeList.length; i++) {
        if (e == this.codeList[i].name) {
          this.codeLength = this.codeList[i].value
        }
      }
    },
    deleteRow(index) {
      this.barcodeRule.splice(index, 1)
      this.barcodeRule = JSON.parse(JSON.stringify(this.barcodeRule))
    },
    ruleChange(e, index) {
      for (let i = 0; i < this.ruleList.length; i++) {
        if (this.ruleList[i].identifier == e) {
          this.$set(this.barcodeRule[index], 'length', this.ruleList[i].length.length > 2 ? '' : this.ruleList[i].length)
          this.$set(this.barcodeRule[index], 'maxlength', this.ruleList[i].maxlength)
          this.$set(this.barcodeRule[index], 'placeholder', this.ruleList[i].maxlength + '')
          this.$set(this.barcodeRule[index], 'fixed_length', this.ruleList[i].fixed_length)
        }
      }
    },
    addRow(index) {
      let obj = {
        identifier: '',
        length: ''
      }
      this.barcodeRule.splice(index + 1, 0, obj)
      this.barcodeRule = JSON.parse(JSON.stringify(this.barcodeRule))
    },
    closeRule() {
      this.$emit('closeRule', false)
    },
    sureDelete() {
      if (!this.choodeCode) {
        this.$Message.warning(`请选择主条码再确认`)
        return
      }
      if (!this.ipt1) {
        this.$Message.warning(`请填写主条码再确认`)
        return
      }
      for (let i = 0; i < this.barcodeRule.length; i++) {
        if (!this.barcodeRule[i].identifier && this.barcodeRule[i].identifier !== '00') {
          this.$Message.warning(`请完善第${i + 1}组识别码再确定`)
          return
        }
        // if (this.barcodeRule[i].maxlength && this.barcodeRule[i].maxlength < this.barcodeRule[i].length.length) {
        //   this.$Message.warning(`第${i + 1}组识别码长度需在${this.barcodeRule[i].length}位数以内`)
        //   return
        // }
      }
      this.$Message.success(`条码维护成功`)
      this.$emit('sureBrn', this.choodeCode, this.ipt1, this.barcodeRule)
    }
  }
}
</script>

<style scoped lang="less">
.foot {
  text-align: right;
  margin-top: 23px;
}
.topModal {
  padding-bottom: 56px;
  margin-top: 14px;
  .tipsImg {
    width: 36px;
    height: 36px;
    margin: 0 13px 0 9px;
  }
  .flDiv {
    p:nth-child(1) {
      display: block;
      height: 24px;
      font-size: 18px;
      font-weight: 600;
      color: rgba(51, 51, 51, 1);
      line-height: 24px;
      margin-bottom: 7px;
    }
    p:nth-child(2) {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
  }
}
.iviewIptWidth557 {
  width: 518px;
}
.iviewIptWidth230 {
  width: 230px;
}
</style>
